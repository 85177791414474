<template>
  <div class="product-item text-center" v-for="(item, i) in Items" :key="i" @mouseover="showByIndex = i"
    @mouseout="showByIndex = null">
    <a :href="item.url">
      <div class="product-img">
        <img v-if="showByIndex === i" v-show="showByIndex === i"
          :src="`https://za.sunglasshut.com/shared_assets/product_images/${item.imageOne}_030A.jpg`"
          :alt="`${item.name}`" />
        <img v-else :src="`https://za.sunglasshut.com/shared_assets/product_images/${item.imageOne}_000A.jpg`"
          :alt="`${item.name}`" />
      </div>
      <div class="product-info pb-0">
        <div class="product-name">{{ item.brand }}</div>
        <div v-if="item.band_sub" class="product-name-sub"><span  class="bold" style="font-weight: 600!important;">{{ item.band_sub }}</span></div>
        <div v-if="item.band_sub2" class="product-name-sub2"><span class="bold" style="font-weight: 600!important;">{{ item.band_sub2 }}</span></div>
        <div v-if="item.text" class="product-name-sub2 mt-2">{{ item.text }}</div>
        <div v-if="item.extra" class="product-name-sub2 extra" style="color: rgb(229 231 235);">{{ item.extra }} </div>
        <p v-if="item.sale_price" class="product-price red-text mb-0">{{ item.sale_price }}</p>
        <p v-if="item.price" :class="[item.sale_price ? 'product-price line-through' : 'product-price']">{{ item.price }}
        </p>
       <!-- <a :href="`${item.url}`" class="btn-shop button">Shop Now</a>-->
      </div>
    </a>
  </div>
</template>
<script>
import "./HomeProducts.scss";
export default {
  name: "HomeProducts",
  data() {
    return {
      showByIndex: null,
      hover: false,
      active: true,
      timer: null,
      current: 0,
      Items: [
        {
          imageOne: "8056262501856",
          brand: "Ray-Ban",
        
          price: "",
          sale_price: "",
          url: "/sunglasshut-sa/Ray-Ban/RB2289-Mega-Balorama-8056262501856",
          text: "RB2289 Mega Balorama",
          extra:"Exclusive",
        },
        {
          imageOne: "8056262492338",
          brand: "Ray-Ban",
          
          price: "",
          sale_price: "",
          url: "/sunglasshut-sa/Ray-Ban/RB3016-Clubmaster-8056262492338",
          text: "RB3016 Clubmaster",
          extra:"Exclusive",
          
        },
        {
          imageOne: "8056262484746",
          brand: "Ray-Ban",
          
          price: "",
          sale_price: "",
          url: "/sunglasshut-sa/Ray-Ban/RB0840S-Mega-Wayfarer-8056262484746",
          text: "RB0840S Mega Wayfarer"
        },
        {
          imageOne: "805289126089",
          brand: "Ray-Ban",
          
          price: "",
          sale_price: "",
          url: "/sunglasshut-sa/Ray-Ban/RB4089-Balorama-805289126089",
          text: "RB4089 Balorama"
        },
        {
          imageOne: "8056597837156",
          brand: "Ray-Ban",
          
          price: "",
          sale_price: "",
          url: "/sunglasshut-sa/Ray-Ban/RB0316S-Mega-Clubmaster-8056597837156",
          text: "RB0316S Mega Clubmaster"
        },
        {
          imageOne: "8056262268216",
          brand: "Ray-Ban",
          
          price: "",
          sale_price: "",
          url: "/sunglasshut-sa/Ray-Ban/RB2289-Mega-Balorama-8056262268216",
          text: "RB2289 Mega Balorama"
        }
      ],
    };
  },
};
</script>
