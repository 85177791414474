export default {
  navigation: [
    {
      title: "women",
      url : "/products/?genders=Female",
      shape: "discover by shape",
      brands: "top brands",
      children: true,
      shapeList: [
        {
          name: "butterfly",
          url:"/products/?genders=Female&shapes=Butterfly",
          bold: false,
        },
        {
          name: "cat eye",
          url: "/products/?genders=Female&shapes=Cat%2520Eye",
          bold: false,
        },
        {
          name: "irregular",
          url: "/products/?genders=Female&shapes=Irregular",
          bold: false,
        },
        {
          name: "oval",
          url: "/products/?genders=Female&shapes=Oval",
          bold: false,
        },
        {
          name: "phantos",
          url: "/products/?genders=Female&shapes=PHANTOS",
          bold: false,
        },
        {
          name: "pilot",
          url: "/products/?genders=Female&shapes=PILOT",
          bold: false,
        },
        {
          name: "pillow",
          url: "/products/?genders=Female&shapes=Pillow",
          bold: false,
        },

        {
          name: "rectangle",
          url: "/products/?genders=Female&shapes=Rectangle",
          bold: false,
        },
        {
          name: "round",
          url: "/products/?genders=Female&shapes=Round",
          bold: false,
        },
        {
          name: "square",
          url: "/products/?genders=Female&shapes=square",
          bold: false,
        },

        {
          name: "discover all",
          url: "/products/?genders=Female",
          bold: true,
        },
      ],
      brandList: [
        {
          name: "ray-ban",
          url: "/products/Ray-Ban/?genders=Female",
          bold: false,
        },
        {
          name: "prada",
          url: "/products/Prada/?genders=Female",
          bold: false,
        },
        {
          name: "burberry",
          url: "/products/Burberry/?genders=Female",
          bold: false,
        },
      ],
    },
    {
      title: "men",
      url : "/products/?genders=Male",
      shape: "discover by shape",
      brands: "top brands",
      children: true,
      shapeList: [
        {
          name: "irregular",
          url: "/products/?genders=Male&shapes=Irregular",
          bold: false,
        },
        {
          name: "oval",
          url: "/products/?genders=Male&shapes=Oval",
          bold: false,
        },
        {
          name: "phantos",
          url: "/products/?genders=Male&shapes=PHANTOS",
          bold: false,
        },
        {
          name: "pilot",
          url: "/products/?genders=Male&shapes=PILOT",
          bold: false,
        },
        {
          name: "pillow",
          url: "/products/?genders=Male&shapes=Pillow",
          bold: false,
        },
        {
          name: "square",
          url: "/products/?genders=Male&shapes=Square",
          bold: false,
        },
        {
          name: "rectangle",
          url: "/products/?genders=Male&shapes=Rectangle",
          bold: false,
        },
        {
          name: "round",
          url: "/?genders=Male&shapes=Round",
          bold: false,
        },
        {
          name: "discover all",
          url: "/?genders=Male",
          bold: true,
        },
      ],
      brandList: [
        {
          name: "ray-ban",
          url: "/products/Ray-Ban/?genders=Male",
          bold: false,
        },
        {
          name: "oakley",
          url: "/products/Oakley/?genders=Male",
          bold: false,
        },
        {
          name: "Tom Ford",
          url: "/products/Tom%20Ford/?genders=Male",
          bold: false,
        },
        {
          name: "arnette",
          url: "/products/Arnette/?genders=Male",
          bold: false,
        },
      ],
    },
    {
      title: "brands",
      url : "/products/",
      brand: "all brands",
      children: true,
      brandsListx: [
        {
          name: "Arnette",
          url: "/products/Arnette/",
          bold: false,
          new: false,
        },

        {
          name: "Burberry",
          url: "/products/Burberry/",
          bold: false,
          new: false,
        },
        {
          name: "Celine",
          url: "/products/Celine/",
          bold: false,
          new: false,
        },
        {
          name: "Costa Del Mar",
          url: "/products/Costa+Del+Mar/",
          bold: false,
          new: false,
        },
        {
          name: "Diesel",
          url: "/products/Diesel/",
          bold: false,
          new: true,
        },
        {
          name: "Dolce&Gabbana",
          url: "/products/Dolce%26Gabbana/",
          bold: false,
          new: false,
        },
        {
          name: "Emporio Armani",
          url: "/products/Emporio Armani/",
          bold: false,
          new: false,
        },
        {
          name: "Ferrari",
          url: "/products/Ferrari/",
          bold: false,
          new: true,
        },
        {
          name: "Giorgio Armani",
          url: "/products/Giorgio Armani/",
          bold: false,
          new: false,
        },{
          name: "Gucci",
          url: "/products/Gucci/",
          bold: false,
          new: false,
        },
        {
          name: "Jimmy Choo",
          url: "/products/Jimmy Choo/",
          bold: false,
          new: true,
        },
        {
          name: "Michael Kors",
          url: "/products/Michael Kors/",
          bold: false,
          new: false,
        },
        {
          name: "Miu-Miu",
          url: "/products/Miu-Miu/",
          bold: false,
          new: false,
        },
     

      ],

      brandsListy: [
        {
          name: "Oakley",
          url: "/products/Oakley/",
          bold: false,
          new: false,
        },
        {
          name: "Oliver Peoples",
          url: "/products/Oliver Peoples/",
          bold: false,
          new: false,
        },
        {
          name: "Persol",
          url: "/products/Persol/",
          bold: false,
          new: false,
        },
        {
          name: "Prada",
          url: "/products/Prada/",
          bold: false,
          new: false,
        },{
          name: "Prada Linea Rossa",
          url: "/products/Prada Linea Rossa/",
          bold: false,
          new: false,
        },{
          name: "Ralph",
          url: "/products/Ralph/",
          bold: false,
          new: false,
        },
        {
          name: "Ray-Ban",
          url: "/products/Ray-Ban/",
          bold: false,
          new: false,
        },
        {
          name: "Ray-Ban Kids",
          url: "/products/Ray-Ban Kids/",
          bold: false,
          new: false,
        },
        {
          name: "Scuderia Ferrari",
          url: "/products/Scuderia Ferrari/",
          bold: false,
          new: true,
        },
        {
          name: "Swarovski",
          url: "/products/Swarovski/",
          bold: false,
          new: false,
        },
        {
          name: "Tiffany",
          url: "/products/Tiffany/",
          bold: false,
          new: false,
        },
        {
          name: "Tom Ford",
          url: "/products/Tom%20Ford/",
          bold: false,
          new: false,
        },
        {
          name: "Versace",
          url: "/products/Versace/",
          bold: false,
          new: false,
        },
      ],
    },
    { title: "best sellers", url:'/products/?collection=Best%2520seller', children: false },
    {
      title: "perfect pair promise",
      url: "/perfect-pair-promise",
      children: false,
    },
  //  { title: "Buy Now", url:'https://www.zando.co.za/mlp-sunglass-hut/', children: false, target: "_blank" },
    {
      title: "Find a store",
      url: "/store-locator",
      children: false,
    },
  ],
};
