export default {
    list: [
        {
            name: "Ray-Ban",
            url: "/products/Ray-Ban/",
            desc: "Born out of necessity in 1937, Ray-ban has been at the leading edge of developing high quality sunglasses to protect pilots from the sun's harsh rays. Since then, Ray-Ban has been the eyewear of choice for visionaries and cultural creators everywhere to express their fearless and most authentic selves.",
            img: "logos/ray-ban_wt_18.png"
        },
        {
            name: "Versace",
            url: "/products/Versace/",
            desc: "Versace is distinguished by its strength of character, innovative use of new, unexpected materials, and varying style of inspiration and performance. The Versace sunglass collection is an elegantly designed line of distinctive eyewear available in unisex designs for those who choose to express their strength, confidence, and uniqueness through a bold and distinctive personal style.",
            img: "logos/logo-versace-new.png"

        },
        {
            name: "Arnette",
            desc: "Founded in 1992, Arnette quickly became the leader in action sport and youth lifestyle eyewear. Arnette is dedicated to progressive design, maximum functionality and unparalleled quality. Supported by a powerful roster of athletes, Arnette continues to be the leading choice in action-sports eyewear.",
            url: "/products/Arnette/",
            img: "logos/arnette_bl_18.png"

        },
        {
            name: "Burberry",
            desc: "The first name in British fashion, Burberry eyewear leverages the strength of its 150-year heritage, balancing classic and modern design. Enduring, luxurious, and widely appealing to men and women of all ages, capturing both the stately and adventurous, Burberry reinterprets the tradition of classic British style in a modern and dynamic way.",
            url: "/products/Burberry/",
            img: "logos/BE_Logo_Digital.png"

        },
        {
            name: "Bvlgari",
            desc: "Inspired by Bulgari's extraordinary jewelry design, this collection boasts uncompromising quality and brilliant attention to detail. An expression of elegance and refinement, Bulgari designer sunglasses blend sophistication and intriguing style into an extraordinary luxury.",
            url: "/products/Bvlgari/",
            img: "logos/bulgari_bl_18.png"

        },
        {
            name: "Celine",
            desc: "At CELINE the idea of Parisian chic, with savoir-faire and the finest quality materials as pillars of the house is reinforced by artistic director Hedi Slimane through the ateliers at 16 rue Vivienne. The same values are translated into the eyewear collections with a mix of classic and fashion forward frames depicting a sophisticated and contemporary silhouette.",
            url: "/products/Celine/",
            img: "logos/celine_bl_18.png"

        },

        {
            name: "Costa Del Mar",
            desc: "ABorn On the Water® in the fishing capital of the world, Costa sunglasses are made for those who don't just spend their lives on the water, they come to life on the water. Each pair is packed with polarized, color-enhancing 580® lens technology. The result? Less haze and enhanced contrast and clarity. Available in glass (580G) for superior clarity and scratch resistance or a lightweight, impact-resistant polycarbonate (580P), it's like seeing in high-definition. Experience sunglasses for life's great adventures.",
            url: "/products/Costa+Del+Mar/",
            img: "logos/cdm_logo.png"

        },
        {
            name: "Diesel",
            desc: "Diesel is an innovative international lifestyle company, founded by Renzo Rosso in 1978. Rooted in denim mastery and evolved into being a leader in premium fashion, Diesel is now a true alternative to the established luxury market.",
            url: "/products/Diesel/",
            img: "logos/dl_logo.svg"

        },

        {
            name: "Dolce&Gabbana",
            desc: "Craftsmanship, refinement and timeless elegance: the Dolce&Gabbana Sunglasses stand out for their unmistakable style, where attention to detail and quality of materials are combined with Made in Italy creativity.",
            url: "/products/Dolce%26Gabbana/",
            img: "logos/dolcegabbana_bl_18.png"

        },
        {
            name: "Emporio Armani",
            url: "/products/Emporio Armani/",
            desc: "Emporio Armani is the pioneering line launched by Giorgio Armani in 1981, aimed at the young. Emporio Armani expresses vitality and a carefree attitude, and possesses a particular allure and a special graciousness. This is a Brand rich in ideas, always suggesting new ways of dressing to reflect the lifestyle of today's youth.",
            img: "logos/emporio-armani_bl_18.png"

        },
        {
            name: "Giorgio Armani",
            url: "/products/Giorgio Armani/",
            desc: "The Giorgio Armani collection came into being in 1975, when the designer rewrote the rules for menswear, revolutionising proportions and aiming at an unheard of fluidity and elegance. Since then, the line has never ceased to evolve, with continual research into an aesthetic based on essential, never ostentatious, style as its outstanding trait. Elegant, modern and sophisticated, the Giorgio Armani collection stands out for the excellence of its manufacture, the extreme attention to detail, the purity of the lines and the use of high quality materials.",
            img: "logos/giorgio-armani_bl_18.png"

        },
        {
            name: "Gucci",
            url: "/products/Gucci/",
            desc: "The Gucci brand represents the quintessence of luxury. Modern and sexy, it is an exclusive brand that reflects an elegant lifestyle. The sunwear collection uses only the highest quality materials and offers distinctive shapes enriched with historic icons that celebrate the House of Gucci.",
            img: "logos/gucci_bl_18.png"

        },
        {
            name: "Ferrari",
            url: "/products/Ferrari/",
            desc: "Founded in 1947, Ferrari is known for its high-performance cars and iconic elegance. The ongoing quest for lasting firsts is what fuels Ferrari. Combining revolutionary technological solutions with exceptional artisanal craftsmanship enables the brand to innovate and create exclusive icons that stay timeless in a fast-changing world. From racing car curves to iconic Ferrari logos, the new Ferrari Eyewear collection reflects the storybehind the brand’s heritage and design journey.",
            img: "logos/FH_Logo.png"

        },
        {
            name: "Scuderia Ferrari",
            url: "/products/Scuderia Ferrari/",
            desc: "Inspired by race car design, the history of the Prancing Horse logo is closely linked to Scuderia Ferrari. A lifelong passion unites wearers and creates a unique sense of belonging. Ferrari's racing spirit lives on in emotions that transcend the road and the track, ultimately becoming an authentic attitude toward life. Born from the spirit of competition, Scuderia Ferrari appeals to sports enthusiasts looking for eyewear styles that combine urban active and athleisure.",
            img: "logos/FH_Logo.png"

        },
        {
            name: "Jimmy Choo",
            url: "/products/Jimmy Choo/",
            desc: "Jimmy Choo is a global luxury lifestyle brand with an empowered sense of glamour and a playfully daring spirit. Celebrating confident style and exceptional craftsmanship, Jimmy Choo sunglasses bring glamour to every wardrobe and make a refined finishing touch for any outfit.",
            img: "logos/JC_Logo_Digital.png"

        },
        {
            name: "Michael Kors",
            url: "/products/Michael Kors/",
            desc: "Michael Kors, established in 1981, offers glamourous, sophisticated style for the consummate jetsetter.",
            img: "logos/michael_kors_18.png"

        },
        {
            name: "Miu-Miu",
            url: "/products/Miu-Miu/",
            desc: "Created in 1992, Miu Miu is the most unrestrained portrayal of Miuccia Pradaâ's creativity. Miu Miu conveys a sense of freedom and an ever-young feeling as a state of mind.",
            img: "logos/MU_Logo_Digital.png"

        },
        {
            name: "Oakley",
            url: "/products/Oakley/",
            desc: "Devoted to making the best sunglasses on the planet by letting invention lead the way. With a passion to reinvent from scratch, Oakley sunglasses defy convention and set the standard for design, performance, and protection by wrapping innovation in art.",
            img: "logos/oakley_bl_18.png"

        },
        {
            name: "Oliver Peoples",
            url: "/products/Oliver Peoples/",
            desc: "With a passion for superior eyewear, Oliver Peoples crafts each frame with the finest materials and careful attention to detail. Every style is inspired and created in their West Hollywood studio with extreme attention to detail and focus on the sophistication and intellectual aesthetic that has made the brand iconic.",
            img: "logos/oliver_peoples_18.png"

        },
        {
            name: "Persol",
            url: "/products/Persol/",
            desc: "Designed and crafted in Italy, Persol - from the Italian \"per il sole\" meaning \"for the sun\" - is a favorite among tastemakers and celebrities for its impeccable fit and incredible clarity. The brand's patented Meflecto temples offer a secure fit, while crystal-tempered sunglass lenses provide protection with distortion-free vision. With a brand character that can best be described as classy, exclusive, stylish, and unique, Persol continues to go beyond trends.",
            img: "logos/persol_bl_18.png"

        },
        {
            name: "Prada",
            url: "/products/Prada/",
            desc: "The hallmark styling of this world-renowned fashion leader denotes high quality with a dedication to innovation and a strong tradition of craftsmanship. Prada is synonymous with an understated style that has always anticipated, and often dictated, new trends. Prada sunglasses use only the finest materials to strike the ideal balance of form and function.",
            img: "logos/PR_Logo_Digital.png"

        },
        {
            name: "Prada Linea Rossa",
            url: "/products/Prada Linea Rossa/",
            desc: "Research, technology and design. Technically advanced products that marry elegance and attention to detail, with a high level of comfort for both lifestyle and sports environments: this is the Prada Linea Rossa Eyewear approach.",
            img: "logos/PS_Logo_Digital.png"

        },
        {
            name: "Ralph",
            url: "/products/Ralph/",
            desc: "Ralph Eyewear is a youthful expression of the Ralph Lauren spirit, featuring timeless shapes and vibrant colors with a feminine, carefree sense of style.",
            img: "logos/ralph_bl_18.png"

        },
        {
            name: "Ray-Ban Kids",
            url: "/products/Ray-Ban Kids/",
            desc: "Born out of necessity in 1937, Ray-ban has been at the leading edge of developing high quality sunglasses to protect pilots from the sun's harsh rays. Since then, Ray-Ban has been the eyewear of choice for visionaries and cultural creators everywhere to express their fearless and most authentic selves.",
            img: "logos/ray-ban_wt_18.png"
        },
        {
            name: "Swarovski",
            desc: "A wardrobe essential, meticulously crafted Swarovski sunglasses make it mesmerizing. Catching the light with dazzling crystal embellishments, they're the secret to effortless glamour.",
            url: "/products/Swarovski/",
            img: "logos/sk_logo_digital.png"
        },
        {
            name: "Tiffany",
            url: "/products/Tiffany/",
            desc: "Tiffany Eyewear echoes the spirit of the House’s jewelry collections, its heritage of craftsmanship and 180-year legacy of innovation. With an array of classic to contemporary styles, every pair of Tiffany Eyewear arrives in the famous Tiffany Blue Box®.",
            img: "logos/tiffany_bl_18.png"
        },
        {
            name: "Tom Ford",
            url: "/products/Tom%20Ford/",
            desc: "Tom Ford eschews flashy logos and gimmicky features, in favor of sleek minimalism and carefully chosen details. His unerring eye for design gives his sunglasses a look that is clearly of the moment, yet at home in any era. Tom Ford's collection conveys luxury, glamour and exclusivity, and to guarantee luxury through attention to detail and quality of product.",
            img: "logos/tomford_18.png"
        },
        
    ],
};
